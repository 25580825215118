export const LOGO = require('./main_logo.png')
export const DIGITALSECIMG = require('./digital_1.png')
export const ENTERPRICEAPP = require('./enterprise-application.png')
export const ENTERPRICEBG = require('./enter_price_bg.png')
export const FILEUPLOAD = require('./fileupload.png')
export const MOBILEBG = require('./mobiledev_bg.png')
export const AIBG = require('./ai_bg.png')
export const TESTBG = require('./test_bg.png')
export const BLOCKBG = require('./blockchain_bg.png')
export const SHAREPOINTBG = require('./sharepoint.png')
export const SALESFORCEBG = require('./salesforce_bg.png')
export const SERVICENOWBG = require('./service-now-bg.png')
export const NETSUITEBG = require('./netsuite_bg.png')
export const ORACLEBG = require('./oracle_bg.png')
export const OPENSOURCEBG = require('./opensource_bg.png')
export const CONGITIVEBG = require('./congtive_bg.png')
export const BUSINESSINTEL = require('./business_intel_bg.png')
export const ARVRBG = require('./ar-vr-bg.png')
export const CLOUDBG = require('./cloud_bg.png')
export const CAREERBG = require('./career_bg.png')
export const ABOUTBG = require('./about_bg.png')
export const DIGITALBG = require('./digital_bg.png')
export const EXPERTISEBG = require('./expertise_bg.png')
export const APISERVICE = require('./api.png')
export const STAR = require('./StarFour.png')
export const LINE_1 = require ('./line1.png')
export const FOOTER_LOGO = require('./footerlogo.png')
export const UNI_CLIENTS_1 = require('./clients/NRI.png')
export const UNI_CLIENTS_2 = require('./clients/arc.png')
export const UNI_CLIENTS_3 = require('./clients/buywoods.png')
export const UNI_CLIENTS_4 = require('./clients/citadel-trading.png')
export const UNI_CLIENTS_5 = require('./clients/dyooti.png')
export const UNI_CLIENTS_6 = require('./clients/evolving-tech.png')
export const UNI_CLIENTS_7 = require('./clients/jcs.png')
export const UNI_CLIENTS_8 = require('./clients/krcollege.png')
export const UNI_CLIENTS_9 = require('./clients/musk.png')
export const UNI_CLIENTS_10 = require('./clients/theprintapp.png')
export const UNI_CLIENTS_11 = require('./clients/vs.png')
export const UNI_CLIENTS_12 = require('./clients/Suzhiyam.png')
export const UNI_CLIENTS_HEAD = require('./clients/clients.png')
export const LOGO_NEW = require('./logo.svg')
export const SPECTRUM_bg = require('./specrum-bg.png')   
export const SPECTRUM_batch = require('./Batch.png')   
export const SPECTRUM_star = require('./mdi_star-four-points.png')   
export const SPECTRUM_1 = require('./spectrum-1.png')   
export const SPECTRUM_2 = require('./PenNib.png')
export const SPECTRUM_3 = require('./AppWindow.png')
export const SPECTRUM_4 = require('./TestTube.png')
export const SPECTRUM_5 = require('./Robot.png')
export const SPECTRUM_6 = require('./ShieldCheck.png')
export const SPECTRUM_7 = require('./Devices.png')
export const SPECTRUM_8 = require('./ClockCountdown.png')
export const SPECTRUM_9 = require('./MegaphoneSimple.png')
export const SPECTRUM_10 = require('./GoogleCardboardLogo.png')
export const SPECTRUM_11 = require('./CubeFocus.png')
export const SPECTRUM_12 = require('./Scan.png')
export const SPECTRUM_13 = require('./Cloud.png')
export const SPECTRUM_14 = require('./Coin.png')
export const SPECTRUM_15 = require('./ChartDonut.png')
export const ProductImg = require('./prod-img.png')
export const Shadow = require('./shadow.png')
export const Vector = require('./vector-round.png')
export const VIDEO = require('../video/banner_vider.mp4')
export const  HEDIMER= require('./new/heidmar_logo.png')
export const  ARC= require('./new/arc_logo.png')
export const  PUPPY= require('./new/puppy_logo.png')
export const  ACCUPAYD= require('./new/accupayd_logo.png')
export const  ALWAQT= require('./new/alwaqt_logo.png')
export const  MUSK= require('./new/musk_logo.png')
export const  RAONE= require('./new/raone_logo.png')
export const  PRINTAPP= require('./new/printapp_logo.png')
export const  PETAL= require('./new/petal_logo.png')
export const  NRI= require('./new/nri_logo.png')
export const  WIPRO= require('./new/wipro_logo.png')
export const  MURUGAPPA= require('./new/murugappa_logo.png')
export const  IMPIGER= require('./new/impiger_logo.png')
export const  ECHO= require('./new/echo_logo.png')
export const  SILVI= require('./new/silvi_logo.png')
export const  ECOM= require('./products/e_commerce solutions.png')
export const  SECURED= require('./products/secured_messaging_system.png')
export const  PARKING= require('./products/parking_solutions.png')
export const  FOOD= require('./products/food_ordering_app.png')
export const  TICKET= require('./products/ticketing_solution.png')
export const  CUSTOM= require('./products/customs_management_system.png')
export const  HELATH= require('./products/healthcare_communication_system.png')
export const  STUDENT= require('./products/student_education_app.png')
export const  VECHILE= require('./products/vehicle_tracking_app.png')
export const  PROCESS= require('./products/process_tracking_system.png')
export const  TRACK= require('./products/tracking_solutions.png')
export const  PHONE= require('./phone_logo.svg')
export const  EMAIL= require('./EnvelopeSimpleOpen.svg')
export const  THANKYOU= require('./thank_you_logo.gif')
export const  ACCOUNTABLE= require('./accountable.png')
export const  TRUSTEDPARTNER= require('./trusted-person.png')
export const  RIGHTECH= require('./right-tech.png')
export const  DESIGNTHINKING= require('./digital-thinking.png')
export const  SALESDELIVERY= require('./sales-delivery.png')
export const  SALESFORCE= require('./salesforce.png')
export const  SERVICENOWTHUMB= require('./servicenow-thumb.png')
export const  SERVICENOW= require('./service-now.png')
export const  NETSUITETHUMB= require('./netsuite-thumb.png')
export const  NETSUITE= require('./netsuite.png')
export const  ORACLETHUMB= require('./oracle-thumb.png')
export const  ORACLE= require('./oracle.png')
export const  OPENSOURCETHUMB= require('./opensource-thumb.png')
export const  OPENSOURCE= require('./opensource.png')
export const  BLOCKCHAIN= require('./blockchain.png')
export const  MISSION= require('./mission.png')
export const  VISION= require('./vision.png')
export const  STRATEGY= require('./strategy.png')
export const  SMART= require('./smart.png')
export const  FRAMEWORK= require('./framework.png')
export const  WALLET= require('./wallet.png')
export const  CRYPTO= require('./crypto.png')
export const  PRIVATE= require('./Private.png')
export const  ICO= require('./Ico.png')
export const  COGNITIVE= require('./cognitive.png')
export const AI = require('./ai.png')
export const  BUSINESS= require('./business.png')
export const CLOUD = require('./cloud1.png')
export const JOBTYPE = require('./jobtype.png')
export const  EXP= require('./exp.png')
export const MAP = require('./map.png')
export const UIUX = require('./uiux.png')
export const APIINTEGRATION = require('./api_integration.png')
