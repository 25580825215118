import React from 'react'

const Logo = () => {
  return (
    // Elverve blackcolor
    <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="245" height="50" viewBox="0 0 245 50" fill="none">
    <path d="M145.459 10.2092V12.1552V14.2925V18.8905V26.7549V31.8709V37.549V38.7271V39.7909H151.259H151.432V33.4788V28.0098V27.6438V25.3056V23.4314V17.8938V17.7712V16.549V14.7859V10.2092H149.095H145.459Z" fill="url(#paint0_radial_774_85)"/>
    <path d="M167.199 39.2549L165.061 35.8056L163.445 33.201L160.977 29.2173L159.997 27.6275C161.14 27.3655 162.231 26.9184 163.23 26.3039L163.354 26.219L163.444 26.1569C164.181 25.6658 164.822 25.0439 165.335 24.3219C165.704 23.7967 166.02 23.2363 166.279 22.6487C166.775 21.535 167.032 20.329 167.03 19.1095C167.036 18.2029 166.903 17.3007 166.635 16.4346C166.053 14.6681 164.911 13.1394 163.383 12.0801C161.609 10.8361 159.268 10.2124 156.36 10.2092H154.4V14.7843H156.36C156.839 14.7816 157.317 14.8298 157.786 14.9281C158.539 15.0683 159.235 15.4229 159.791 15.9493C160.37 16.5248 160.753 17.268 160.886 18.0735C160.921 18.2615 160.946 18.4514 160.961 18.6422C160.971 18.7745 160.976 18.9118 160.976 19.049C160.976 19.067 160.976 19.0817 160.976 19.0997C160.974 19.3017 160.963 19.5034 160.941 19.7043C160.837 20.75 160.451 21.6029 159.788 22.2565C159.481 22.5568 159.122 22.8002 158.73 22.9755C157.979 23.2913 157.17 23.4434 156.356 23.4216H154.396V29.6846L155.213 31.0425L156.909 33.8677L157.049 34.0997L160.459 39.781H167.535L167.199 39.2549Z" fill="url(#paint1_radial_774_85)"/>
    <path d="M4.90039 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H4.97552H9.40186H10.8376V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H4.90039V13.6732Z" fill="url(#paint2_radial_774_85)"/>
    <path d="M21.8495 27.0768H22.0063H24.4057V25.3382V25.2761V23.2549V22.5H13.7988V23.8742V24.0621V27.0768H16.6049H21.8495Z" fill="url(#paint3_radial_774_85)"/>
    <path d="M24.2032 10.1667H19.9157H13.7988V14.7435H15.1137H16.9838H19.8291H23.7034H25.6781V10.1667H24.2032Z" fill="url(#paint4_radial_774_85)"/>
    <path d="M21.1374 35.2565H18.7217H13.7988V35.5212V39.8333H16.3468H19.4812H25.6781V39.6863V37.5245V35.2565H24.9088H21.1374Z" fill="url(#paint5_radial_774_85)"/>
    <path d="M110.805 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H110.88H115.306H116.742V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H110.805V13.6732Z" fill="url(#paint6_radial_774_85)"/>
    <path d="M127.754 27.0768H127.909H130.31V25.3382V25.2761V23.2549V22.5H119.703V23.8742V24.0621V27.0768H122.508H127.754Z" fill="url(#paint7_radial_774_85)"/>
    <path d="M130.107 10.1667H125.82H119.703V14.7435H121.018H122.888H125.733H129.608H131.581V10.1667H130.107Z" fill="url(#paint8_radial_774_85)"/>
    <path d="M127.042 35.2565H124.624H119.703V35.5212V39.8333H122.251H125.385H131.581V39.6863V37.5245V35.2565H130.811H127.042Z" fill="url(#paint9_radial_774_85)"/>
    <path d="M58.962 35.2565H57.247H55.7557H50.5977V37.3758V39.7909H52.0105H53.7222H58.8476H59.5173V35.6912V35.2565H58.962Z" fill="url(#paint10_radial_774_85)"/>
    <path d="M41.6621 10.2092V15.2598V17.5147V24.4281V25.866V29.7239V32.4428V36.4444V39.4493V39.7909H47.6352V39.6503V35.8121V35.2565V32.9216V28.6503V25.3529V23.4444V18.6667V17.3137V16.0294V10.2092H44.0794H41.6621Z" fill="url(#paint11_radial_774_85)"/>
    <path d="M97.8219 10.2092H93.9624L93.9346 10.2925L93.113 12.6863L90.4311 20.5098L89.5148 23.183L88.2996 26.7288L87.4355 29.2484L88.1901 31.4477L89.2371 34.5049L90.2874 37.567L91.2216 35.018L91.9386 33.0605L92.9938 30.1846L94.6663 25.6193L96.6982 20.0784L98.6337 14.8007L100.316 10.2092H97.8219Z" fill="url(#paint12_radial_774_85)"/>
    <path d="M85.8712 33.8154L85.195 31.8415L84.1333 28.7435L82.4314 23.781L81.2864 20.4395L79.9928 16.6683L79.5861 15.4788L77.7796 10.2092H75.8523H71.4668L73.0969 14.6716L74.8184 19.3873L76.1724 23.0964L76.4387 23.8252L76.6036 24.2745L77.3125 26.2157L79.328 31.7353L80.7768 35.7026L82.2697 39.7909H85.9741H87.1452H87.9194L87.56 38.7435L85.8712 33.8154Z" fill="url(#paint13_radial_774_85)"/>
    <path d="M216.709 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H216.786H221.21H222.646V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H216.709V13.6732Z" fill="url(#paint14_radial_774_85)"/>
    <path d="M233.66 27.0768H233.815H236.216V25.3382V25.2761V23.2549V22.5H225.607V23.8742V24.0621V27.0768H228.413H233.66Z" fill="url(#paint15_radial_774_85)"/>
    <path d="M236.012 10.1667H231.726H225.607V14.7435H226.924H228.794H231.638H235.514H237.487V10.1667H236.012Z" fill="url(#paint16_radial_774_85)"/>
    <path d="M232.946 35.2565H230.53H225.607V35.5212V39.8333H228.157H231.29H237.487V39.6863V37.5245V35.2565H236.717H232.946Z" fill="url(#paint17_radial_774_85)"/>
    <path d="M203.728 10.2092H199.869L199.839 10.2925L199.019 12.6863L196.337 20.5098L195.421 23.183L194.206 26.7288L193.342 29.2484L194.095 31.4477L195.143 34.5049L196.192 37.567L197.128 35.018L197.845 33.0605L198.898 30.1846L200.573 25.6193L202.604 20.0784L204.538 14.8007L206.222 10.2092H203.728Z" fill="url(#paint18_radial_774_85)"/>
    <path d="M191.774 33.8154L191.098 31.8415L190.036 28.7435L188.336 23.781L187.189 20.4395L185.897 16.6683L185.489 15.4788L183.682 10.2092H181.757H177.371L179 14.6716L180.723 19.3873L182.077 23.0964L182.343 23.8252L182.508 24.2745L183.217 26.2157L185.232 31.7353L186.681 35.7026L188.174 39.7909H191.878H193.049H193.824L193.464 38.7435L191.774 33.8154Z" fill="url(#paint19_radial_774_85)"/>
    <defs>
    <radialGradient id="paint0_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5391 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint1_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5374 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint2_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5379 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint3_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5379 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint4_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5379 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint5_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5379 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint6_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5389 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint7_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5389 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint8_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5388 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint9_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5388 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint10_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5381 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint11_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5392 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint12_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5383 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint13_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.538 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint14_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5382 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint15_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5381 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint16_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5383 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint17_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5383 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint18_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5374 19.8497) scale(265.047 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    <radialGradient id="paint19_radial_774_85" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-14.5392 19.8497) scale(265.048 265.154)">
    <stop stop-color="#0D47A1"/>
    <stop offset="1" stop-color="#00BCD4"/>
    </radialGradient>
    </defs>
    </svg>
  
   
  
    
  )
}

export default Logo