import React from 'react'

const BlackLogo = () => {
  return (

    // technowhitecolor
<svg className='logo' xmlns="http://www.w3.org/2000/svg" width="245" height="50" viewBox="0 0 245 50" fill="none">
<path d="M146.766 10.2092V12.1552V14.2925V18.8905V26.7549V31.8709V37.549V38.7271V39.7909H152.566H152.739V33.4788V28.0098V27.6438V25.3056V23.4314V17.8938V17.7712V16.549V14.7859V10.2092H150.401H146.766Z" fill="white"/>
<path d="M168.505 39.2549L166.367 35.8056L164.752 33.201L162.284 29.2173L161.304 27.6275C162.446 27.3655 163.538 26.9184 164.536 26.3039L164.66 26.219L164.75 26.1569C165.487 25.6658 166.128 25.0439 166.642 24.3219C167.011 23.7967 167.327 23.2362 167.586 22.6487C168.082 21.535 168.338 20.329 168.337 19.1095C168.343 18.2029 168.21 17.3007 167.942 16.4346C167.36 14.6681 166.218 13.1394 164.69 12.0801C162.916 10.8361 160.575 10.2124 157.666 10.2092H155.706V14.7843H157.666C158.145 14.7816 158.623 14.8298 159.092 14.9281C159.845 15.0683 160.542 15.4229 161.098 15.9493C161.677 16.5248 162.06 17.268 162.192 18.0735C162.228 18.2615 162.253 18.4514 162.267 18.6422C162.277 18.7745 162.282 18.9118 162.282 19.049C162.282 19.067 162.282 19.0817 162.282 19.0997C162.281 19.3016 162.269 19.5034 162.248 19.7042C162.143 20.75 161.758 21.6029 161.095 22.2565C160.787 22.5568 160.429 22.8002 160.036 22.9755C159.286 23.2913 158.477 23.4434 157.663 23.4216H155.703V29.6846L156.52 31.0425L158.215 33.8676L158.356 34.0997L161.766 39.781H168.842L168.505 39.2549Z" fill="white"/>
<path d="M6.20703 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H6.28216H10.7085H12.1442V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H6.20703V13.6732Z" fill="white"/>
<path d="M23.1562 27.0768H23.313H25.7123V25.3382V25.2761V23.2549V22.5H15.1055V23.8742V24.0621V27.0768H17.9115H23.1562Z" fill="white"/>
<path d="M25.5098 10.1667H21.2223H15.1055V14.7435H16.4203H18.2905H21.1357H25.01H26.9847V10.1667H25.5098Z" fill="white"/>
<path d="M22.444 35.2565H20.0283H15.1055V35.5212V39.8333H17.6535H20.7878H26.9847V39.6863V37.5245V35.2565H26.2154H22.444Z" fill="white"/>
<path d="M112.111 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H112.186H116.613H118.048V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H112.111V13.6732Z" fill="white"/>
<path d="M129.06 27.0768H129.216H131.617V25.3382V25.2761V23.2549V22.5H121.01V23.8742V24.0621V27.0768H123.814H129.06Z" fill="white"/>
<path d="M131.414 10.1667H127.127H121.01V14.7435H122.325H124.195H127.04H130.914H132.887V10.1667H131.414Z" fill="white"/>
<path d="M128.348 35.2565H125.931H121.01V35.5212V39.8333H123.558H126.692H132.887V39.6863V37.5245V35.2565H132.118H128.348Z" fill="white"/>
<path d="M60.2686 35.2565H58.5536H57.0624H51.9043V37.3758V39.7909H53.3171H55.0289H60.1543H60.8239V35.6912V35.2565H60.2686Z" fill="white"/>
<path d="M42.9707 10.2092V15.2598V17.5147V24.4281V25.866V29.7239V32.4428V36.4444V39.4493V39.7909H48.9438V39.6503V35.8121V35.2565V32.9216V28.6503V25.3529V23.4444V18.6667V17.3137V16.0294V10.2092H45.388H42.9707Z" fill="white"/>
<path d="M99.1285 10.2092H95.269L95.2412 10.2925L94.4197 12.6863L91.7377 20.5098L90.8214 23.183L89.6062 26.7288L88.7422 29.2484L89.4968 31.4477L90.5437 34.5049L91.594 37.567L92.5283 35.018L93.2453 33.0605L94.3004 30.1846L95.973 25.6193L98.0048 20.0784L99.9403 14.8007L101.623 10.2092H99.1285Z" fill="white"/>
<path d="M87.1778 33.8154L86.5016 31.8415L85.4399 28.7435L83.738 23.781L82.593 20.4395L81.2994 16.6683L80.8927 15.4788L79.0863 10.2092H77.1589H72.7734L74.4035 14.6716L76.125 19.3873L77.4791 23.0964L77.7453 23.8252L77.9103 24.2745L78.6191 26.2157L80.6347 31.7353L82.0834 35.7026L83.5763 39.7909H87.2807H88.4518H89.226L88.8667 38.7435L87.1778 33.8154Z" fill="white"/>
<path d="M218.016 13.6732V18.6389V18.8023V25.3529V25.8317V33.25V34.1569V34.9232V39.8154V39.8333H218.092H222.517H223.953V36.1683V29.9951V27.6193V24.3546V22.2565V17.3056V16.0163V10.1667H218.016V13.6732Z" fill="white"/>
<path d="M234.966 27.0768H235.122H237.523V25.3382V25.2761V23.2549V22.5H226.914V23.8742V24.0621V27.0768H229.72H234.966Z" fill="white"/>
<path d="M237.318 10.1667H233.033H226.914V14.7435H228.231H230.101H232.944H236.82H238.793V10.1667H237.318Z" fill="white"/>
<path d="M234.253 35.2565H231.837H226.914V35.5212V39.8333H229.464H232.596H238.793V39.6863V37.5245V35.2565H238.024H234.253Z" fill="white"/>
<path d="M205.035 10.2092H201.175L201.146 10.2925L200.326 12.6863L197.644 20.5098L196.728 23.183L195.512 26.7288L194.648 29.2484L195.401 31.4477L196.45 34.5049L197.499 37.567L198.435 35.018L199.152 33.0605L200.205 30.1846L201.879 25.6193L203.911 20.0784L205.845 14.8007L207.529 10.2092H205.035Z" fill="white"/>
<path d="M193.08 33.8154L192.404 31.8415L191.343 28.7435L189.642 23.781L188.496 20.4395L187.204 16.6683L186.795 15.4788L184.989 10.2092H183.063H178.678L180.306 14.6716L182.029 19.3873L183.383 23.0964L183.65 23.8252L183.815 24.2745L184.523 26.2157L186.539 31.7353L187.988 35.7026L189.481 39.7909H193.185H194.356H195.13L194.771 38.7435L193.08 33.8154Z" fill="white"/>
</svg>
    
  
  
    
  )
}

export default BlackLogo